<template>
  <div class="personal-details-contend" >
      <p class="pb-1">USER INFORMATION</p>
      <validation-observer ref="personalDetails" class="auth-register-form  d-block">
        <b-form
          v-if="Object.keys(personalFormData).length"
          class="row needs-validation h-100"
        >
          <b-col cols="12" class="p-0 row d-flex align-content-start m-0">
            <b-col
              cols="12"
              md="6"
              class="mx-auto mb-1"
            >
              <InputField :id="'full-name'" :rules="{}" :disabled="true" :placeholder="'Full Name'" :describedby="'full-name-feedback'" :label="'Full Name'" :name="'Full Name'" :modelValue="personalFormData.fullname" />
            </b-col>

            <b-col
              cols="12"
              md="6"
              class="mx-auto mb-1"
            >
              <InputField :id="'company-name'" :rules="{}" :disabled="true" :placeholder="'Company Name'" :describedby="'email-feedback'" :label="'Company Name'" :name="'Company Name'" :modelValue="personalFormData.companyName" />
            </b-col>

            <b-col
              cols="12"
              md="6"
              class="mx-auto mb-1"
            >
              <InputField :id="'email'" :rules="{}" :disabled="true" :placeholder="'Email address'" :describedby="'email-feedback'" :label="'Email address'" :name="'Email'" :modelValue="personalFormData.email" />
            </b-col>

            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <InputField :id="'phone'" :rules="{}" :disabled="true" :placeholder="'phone'" :describedby="'phone-feedback'" :label="'Phone'" :name="'phone'" :modelValue="personalFormData.phone" />
            </b-col>

            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <InputField :id="'registration-number'" :disabled="true" :rules="{}" :placeholder="'SEBI Registration number'" :describedby="'registration-number'" :label="'SEBI Registration number'" :name="'registration-number'" :modelValue="personalFormData.sebiRegistration" />
            </b-col>

            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <label class="mr-1" for="password">Password</label>
              <feather-icon v-b-modal.modal-update-pass icon="EditIcon" stroke="#000" size="16"/>
              <InputField :id="'password'" :disabled="true" :rules="{}" type="password" :placeholder="'Password'" :describedby="'password-feedback'"  :name="'password'" label="" :modelValue="personalFormData.password" />
              
            </b-col>

            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <label class="mr-1" for="profile">Public Profile</label>
              <feather-icon icon="Link2Icon" class="cursor-pointer" stroke="#000" size="16" @click="copyLink(personalFormData.publicProfile)"/>
              <InputField :id="'profile'" :disabled="true" :rules="{}" :placeholder="'Public Profile'" :describedby="'profile-feedback'"  :name="'profile'" label="" :modelValue="personalFormData.publicProfile" />
              
            </b-col>

            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <!-- <InputField :id="'member-type'" :rules="{}" :placeholder="'Member type'" :describedby="'member-type-feedback'" :label="'Member type'" :name="'Member type'"  :modelValue="personalFormData.memberType" @input="val => personalFormData.memberType = val" /> -->
            </b-col>

            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <!-- <InputField :id="'defult-disclimer'" type="textarea" :placeholder="'Defult Disclimer'" :describedby="'defult-disclimer'" :label="'Defult Disclimer'" :name="'Defult Disclimer'"  :modelValue="personalFormData.defultDisclimer" @input="val => personalFormData.defultDisclimer = val" /> -->
            </b-col>
          </b-col>

          <hr class="col-12 border-2 m-0">

          <p class="col-12 py-1">BANK INFORMATION</p>

          <b-col cols="12" class="p-0 row d-flex align-content-start m-0">
            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <InputField :id="'bankName'" :disabled="true" :rules="{}" :placeholder="'Account No'" :describedby="'bankName'" :label="'Account No'" :name="'Account'" :modelValue="personalFormData.accountNo" />
            </b-col>

            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <InputField :id="'bank'" :disabled="true" :rules="{}" :placeholder="'Bank Name'" :describedby="'bank-feedback'" :label="'Bank Name'" :name="'Bank'"  :modelValue="personalFormData.bankName" />
            </b-col>

            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <InputField :id="'ifsc-code'" :disabled="true" :rules="{}" :placeholder="'IFSC Code'" :describedby="'ifsc-code'" :label="'IFSC Code'" :name="'first Name'"  :modelValue="personalFormData.ifscCode" />
            </b-col>

            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <InputField :id="'account-type'" :disabled="true" :rules="{}" :placeholder="'Account Type'" :describedby="'account-type'" :label="'Account Type'" :name="'Account Type'"  :modelValue="personalFormData.accountType" />
            </b-col>

            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <!-- <InputField :id="'payment-gatreway-secret'" :placeholder="'Payment Gatreway Secret'" :describedby="'payment-gatreway-secret'" :label="'Payment Gatreway Secret'" :name="'Payment Gatreway Secret'"  :modelValue="personalFormData.paymentGatrewaySecret" @input="val => personalFormData.paymentGatrewaySecret = val" /> -->
            </b-col>

            <b-col
              sm="12"
              md="6"
              class="mx-auto mb-1"
            >
              <!-- <InputField :id="'brand-name'" :placeholder="'Brand Name'" :describedby="'brand-name'" :label="'Brand Name'" :name="'Brand Name'"  :modelValue="personalFormData.brandName" @input="val => personalFormData.brandName = val" /> -->
            </b-col>
          </b-col>
        </b-form>
      </validation-observer>


      <b-modal id="modal-update-pass" title="BootstrapVue" ref="updateModal" @hide="onPasswordModalClose()">
        <template #modal-header="">
          <div class="d-flex align-items-center">
            <h2 class="mb-0">Update Password</h2>
          </div>

        </template>
        <div>
          <validation-observer ref="simpleRules">
            <div class="mx-2 pt-2">
              <b-row class="m-0">
                <b-col sm="12" md="8">
                  <InputField
                    type="password"
                    :id="'current-password'"
                    :placeholder="'Current Password'"
                    :describedby="'current-password-feedback'"
                    :label="'Current Password'"
                    :name="'current-password'"
                    :rules="{ required: true }"
                    :modelValue="formData.currPassword"
                    @input="(val) => (formData.currPassword = val)"
                    :showValidationFeedback="formSubmitted"
                    :showValidationFeedbackmes="formSubmitted"
                  />
                </b-col>
                <b-col sm="12" md="8">
                  <InputField
                    type="password"
                    :id="'new-password'"
                    :placeholder="'New Password'"
                    :describedby="'new-password-feedback'"
                    :label="'New Password'"
                    :name="'new-password'"
                    :rules="{ required: true, min: 8, max: 20, regex: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$_()]).{8,}/ }"
                    :modelValue="formData.newPassword"
                    @input="(val) => (formData.newPassword = val)"
                    :showValidationFeedback="formSubmitted"
                    :showValidationFeedbackmes="formSubmitted"
                  />
                </b-col>
                <b-col sm="12" md="8">
                  <InputField
                    type="password"
                    :id="'confirm-new-password'"
                    :placeholder="'Confirm New Password'"
                    :describedby="'confirm-new-password-feedback'"
                    :label="'Confirm New Password'"
                    :name="'confirm-new-password'"
                    :rules="{ required: true, is: formData.newPassword, }"
                    :showValidationFeedback="formSubmitted"
                    :showValidationFeedbackmes="formSubmitted"
                    />
                </b-col>

                <b-col class="mt-1" sm="12" md="12">
                  <h5>Password Policy</h5>
                  <ul>
                    <li><strong>Minimum Length:</strong> 8 characters</li>
                    <li><strong>Maximum Length:</strong> 20 characters</li>
                    <li><strong>Required Characters:</strong> Alphabets (A to Z)(a to z), Digits (0 to 9), or Special Characters (~!@#$_)</li>
                  </ul>
                </b-col>
              </b-row>
            </div>
          </validation-observer>
        </div>
        <template #modal-footer="{ cancel }">
          <b-button size="md" class="cancel" @click="cancel()"> Cancel </b-button>
          <b-button size="md" variant="primary" @click="updatePass()"> Update </b-button>
        </template>
      </b-modal>
  </div>
</template>


<script>
import { ref, toRef, reactive, inject, onMounted } from '@vue/composition-api'
import InputField from '@/views/components/form/InputField.vue';
import VueCountryCode from "vue-country-code";
import SelectField from '@/views/components/form/SelectField.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from 'vue'

export default {
components: {
  InputField,
  VueCountryCode,
  SelectField
},
setup() {
  const $http = inject("$http");
  let personalFormData = ref({});
  const personalDetails = ref(null);
  const invalid = ref(false);
  const updateModal = ref(null);
  const simpleRules = ref("");
  const formData = reactive({
    currPassword: "",
    newPassword: ""
  });

  const updatePass = () => {
    simpleRules.value.validate().then(async (success) => {
      if (success) {
          await $http
            .put(`/advisor/password/update`,formData)
            .then((response) => {
              console.log(response, "response");
              if(response.status == 204) {
                updateModal.value.hide()
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Success`,
                    icon: "AlertCircleIcon",
                    variant: "success",
                    text:'Password updated successfully!',
                    closeOnClick: false,
                    timeout: false,
                  },
                });
              } else {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Error`,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                    text: "Something want to wrong",
                    closeOnClick: false,
                    timeout: false,
                  },
                });
              }
            })
            .catch((error) => {
              console.log('update error -->', error);
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: `Error`,
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: error.response,
                  closeOnClick: false,
                  timeout: false,
                },
              });
            });
        }
      });
  }
  const getValidationState = ({ dirty, validated, valid = null }) => {
    return dirty || validated ? valid : null;
  }

  const getUserData = async () => {
    await $http
      .get(`/advisor/profile`)
      .then((response) => {
        personalFormData.value = response.data
      })
      .catch((error) => {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: `Error`,
            icon: "AlertCircleIcon",
            variant: "danger",
            text: error.response,
            closeOnClick: false,
            timeout: false,
          },
        });
      });
  };

  const onPasswordModalClose = () => {
    formData.currPassword = ""
    formData.newPassword = ""
  };

  const copyLink= (link) => {
    const tempInput = document.createElement('input');
    tempInput.setAttribute('value', link);
    document.body.appendChild(tempInput);

    // Select the input element's content
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected content to the clipboard
    document.execCommand('copy');

    // Remove the temporary input
    document.body.removeChild(tempInput);
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: `Success`,
        icon: "AlertCircleIcon",
        variant: "success",
        text:'Link copied to clipboard!',
        closeOnClick: false,
        timeout: false,
      },
    });
  }
  
  onMounted(async () => {
    await getUserData();
  });

  return {
    personalFormData,
    personalDetails,
    updatePass,
    invalid,
    getValidationState,
    formData,
    simpleRules,
    updateModal,
    onPasswordModalClose,
    copyLink
  }
}
}
/* eslint-disable global-require */
</script>

<style lang="scss">
.form-control.is-valid {
  background-image: none;
}
</style>
