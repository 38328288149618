<template>
    <b-row class="m-0 main-section mb-1">
      <b-col cols="12">
        <div class="d-block d-md-flex justify-content-between align-items-center">
          <div class="mb-2 m-md-0">
            <h2>My Account</h2>
          </div>
        </div>
      </b-col>
    </b-row>
  </template>
    <script>
  import { ref } from "@vue/composition-api";
  export default {
    components: {},
    setup() {
      return {
      };
    },
  };
  </script>